import React, {useState, useEffect} from 'react';
import {Box, Typography, useMediaQuery, Checkbox, FormControlLabel, IconButton} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {activateDisciple, addAddressApi, addDisciple, deleteUser, getAdminOrgDisciplesApi, getDiscipleDetailsApi} from '../../api';
import AdminStructure from './common/AdminStructure';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {BASE_URL, CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../../components/common/Modal';
import {Edit} from '@mui/icons-material';
import EditDisciple from './components/EditDisciple';
import AddDisciple from './components/AddDisciple';
import toast from 'react-hot-toast';
import useAuthStore from '../../store/authStore';
import {isEmptyObjectValues} from '../../utils';

function Disciples() {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [selectedDisciple, setSelectedDisciple] = useState(null);
  const [activateDiscipleData, setActivateDiscipleData] = useState(null);
  const [adminDisciple, setAdminDisciple] = useState([]);
  const [discipleData, setDiscipleData] = useState([]);
  const [searchDisciples, setSearchDisciples] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [isAddDiscipleOpen, setIsAddDiscipleOpen] = useState(false);

  const [isPromoteOpen, setIsPromoteOpen] = useState(false);
  const [d1Checked, setD1Checked] = useState(false);
  const [d2Checked, setD2Checked] = useState(false);
  const [d3Checked, setD3Checked] = useState(false);

  const [disciples, setDisciples] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [isActivateOpen, setIsActivateOpen] = useState(false);

  const [formData, setFormData] = useState({
    zipcode: '',
    city: '',
    state: '',
    country: '',
    birthday: '',
    salvation_year: '',
    mobile_number: '',
    email: '',
    user_id: selectedDisciple?.id || '',
  });

  const [disData, setDisData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const resetData = () => {
    setFormData({
      zipcode: '',
      city: '',
      state: '',
      country: '',
      birthday: '',
      salvation_year: '',
      mobile_number: '',
      email: '',
      user_id:'',
    })
  }

  const openActivateModal = disciple => {
    setActivateDiscipleData(disciple)
    setIsActivateOpen(true);
  };
  const closeActivateModal = () => {
    setActivateDiscipleData(null);
    setIsActivateOpen(false);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditModalOpen = (data) => {
    setFormData(data);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setFormData({});
  };

  const handleSaveChanges = async () => {
    try {
      let requestData = formData;
      requestData['birthday'] = moment(formData.birthday).format("YYYY-MM-DD")
      const response = await addAddressApi(requestData);
      if(response.data?.status === 200) {
        handleEditModalClose();
        resetData();
        getDisciples();
      }
    } catch (error) {
    }
  };

  const onSaveActivateDisciple = async () => {
    if (!activateDiscipleData) {
      return;
    }
    const reqData = {
      discipleId: activateDiscipleData.id
    };
    try {
      const response = await activateDisciple(reqData);
      if (response.data.status === 200) {
        getDisciples()
        closeActivateModal();
      }
    } catch (error) {
      closeActivateModal();
    }
  };

  const handlePromoteChange = setter => event => {
    setter(event.target.checked);
  };

  const handlePromoteSave = () => {
    setIsPromoteOpen(false);
  };

  const handleDiscipleSave = async () => {
    if (isEmptyObjectValues(disData)) {
      toast.error(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    const reqData = {
      first_name: disData.name.split(' ')[0] || '',
      last_name: disData.name.split(' ')[1] || '',
      email: disData.email,
      mobile_number: disData.phone,
      organization_id: selectedOrganization.id
    };
    try {
      const response = await addDisciple(reqData);
      if (response.data.status === 200) {
        setIsAddDiscipleOpen(false);
        setDisData({
          name: '',
          phone: '',
          email: ''
        });
        getDisciples();
        getDiscipleDataHandler();
      }
    } catch (error) {}
  };

  const getDiscipleDataHandler = async () => {
    try {
      const res = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple');
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data.filter(obj => obj.status === 'ACTIVE'));
      }
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      const response = await deleteUser(itemToDelete);
      if (response?.data?.status === 200) {
        setDisciples(disciples.filter(i => i.id !== itemToDelete));
        getDiscipleDataHandler();
        // Refresh or reload the page after a successful delete
        window.location.reload();
      }
      handleMenuClose();
    } catch (error) {
      handleMenuClose();
    }
  };

  // Trigger delete modal with disciple data
  const openDeleteModal = discipleId => {
    setItemToDelete(discipleId); // Set the disciple to delete
    setIsModalOpen(true);
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setIsChecked(false);
    setItemToDelete(null); // Reset item to delete when closing modal
    setIsPromoteOpen(false);
    setIsAddDiscipleOpen(false);
    setDisData({
      name: '',
      phone: '',
      email: ''
    });
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleDiscipleClick = async disciple => {
    setLoader(true);
    setSelectedDisciple(disciple);
    setFormData(prevData => ({
      ...prevData,
      user_id: disciple.id
    }));
    const resp = await getDiscipleDetailsApi(disciple?.id, 'disciple');
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data?.[0]);
      setLoader(false);
    }
    setLoader(false);
  };

  const getDisciples = async () => {
    setLoader(true);
    try {
      setPageLoading(true);
      const response = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple');
      if (response?.data?.status == 200) {
        setAdminDisciple(response?.data?.data);
        setSearchDisciples(response?.data?.data);
      }
      setPageLoading(false);
      setLoader(false);
    } catch (error) {
      setPageLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    // Automatically trigger handleDiscipleClick for the first disciple with ACTIVE user_status when adminDisciple is loaded
    if (adminDisciple?.length > 0) {
      const firstActiveDisciple = adminDisciple.find(disciple => disciple.status === 'ACTIVE'); // Find the first ACTIVE disciple
      if (firstActiveDisciple) {
        handleDiscipleClick(firstActiveDisciple);
      }
    }
  }, [adminDisciple]);

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminDisciple(searchDisciples);
    } else {
      const filteredDisciples = searchDisciples.filter(disciple => disciple?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminDisciple(filteredDisciples);
    }
  };

  useEffect(() => {
    getDisciples();
  }, [selectedOrganization]);

  // Calculate the counts of each status disciple
  const activeDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => disciple.status === 'ACTIVE')?.length : 0;
  const invitedDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => ['COMPLETED', 'ARCHIVED', 'INVITED'].includes(disciple.status))?.length : 0;

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden',
        overflowX: 'hidden'
      }}
    >
      <AdminStructure 
        isSearch={true} 
        searchHandler={searchHandler} 
        handleAddClick={() => setIsAddDiscipleOpen(true)} 
        heading={translate('Disciples')} 
        isAddingDisciple={true}
        >
        {pageLoading ? (
          <Loader color="#9e0001" />
        ) : adminDisciple?.length === 0 ? (
          <NoDataFoundMessage mt={5} message={translate('There are no disciples in this organization')} />
        ) : (
          <Box
            display="flex"
            // justifyContent="space-between"
            px={2}
            gap={2}
            sx={{
              height: {
                md: '88vh',
                xs: 'auto',
                sm: 'auto'
              },
              flexDirection: {
                md: 'row',
                sm: 'column',
                xs: 'column'
              },
              justifyContent: {
                md: 'space-between'
              }
            }}
          >
            <Box width="85%" display={'flex'} flexDirection={'column'} gap={2}>
              <ItemList 
                items={adminDisciple.filter(disc => ['ACTIVE'].includes(disc.status))} 
                handleClick={handleDiscipleClick} 
                selectedItem={selectedDisciple} 
                height={'70%'} 
                title={`${translate('Active Disciples')} (${activeDisciplesCount})`} 
              />
              <ItemList 
                isInvited 
                items={adminDisciple.filter(disc => ['COMPLETED', `ARCHIVED`, `INVITED`].includes(disc.status))} 
                handleClick={() => {}} 
                selectedItem={selectedDisciple} 
                height={'30%'} 
                title={`${translate('Invited Disciples')} (${invitedDisciplesCount})`} 
                openActivateModal={openActivateModal} 
              />
            </Box>

            <Box
              width="68%"
              display="flex"
              flexDirection="column"
              // justifyContent="space-between"
              gap={2}
              sx={{
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {selectedDisciple && (
                <ItemDetails
                  title={selectedDisciple.name}
                  selectedLeader={selectedDisciple}
                  // change later
                  // deleteIcon
                  setIsModalOpen={() => openDeleteModal(selectedDisciple.id)}
                  setIsPromoteOpen={setIsPromoteOpen}
                  height="42%"
                  details={[
                    {
                      name: translate('Date Finished D1:'),
                      detail: discipleData?.d1_completion_date !== 'N/A' ? moment(discipleData?.d1_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate('Date Finished D2:'),
                      detail: discipleData?.d2_completion_date !== 'N/A' ? moment(discipleData?.d2_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate('Date Finished D3:'),
                      detail: discipleData?.d3_completion_date !== 'N/A' ? moment(discipleData?.d3_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A',
                      mb: '7px'
                    },
                    {
                      name: discipleData?.groupsLed,
                      detail: translate('Groups Led'),
                      fontWeight: '600'
                    },
                    {
                      name: discipleData?.disciplesMade,
                      detail: translate('Disciples Made'),
                      mb: '4px',
                      fontWeight: '600'
                    },
                    // change later
                    // {
                    //   name: translate('In Group:'),
                    //   detail: '',
                    //   icon: '',
                    //   fontWeight: '600'
                    // },
                    // {
                    //   name: translate('Approved to Lead D2'),
                    //   detail: '',
                    //   icon: selectedDisciple?.status == 'IN-PROGRESS',
                    //   fontWeight: '600'
                    // }
                  ]}
                  loader={loader}
                />
              )}
              {loader ? (
                <Loader color="#9e0001" />
              ) : (
                <>
                  {selectedDisciple && (
                    <>
                      <Box p={3} height="42%" overflowY="scroll" bgcolor="#FFF" borderRadius={5} display="flex" flexDirection="column" textAlign="left">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800">
                            {selectedDisciple?.name}'s {translate('Profile')}
                          </Typography>
                          {/* change later */}
                          <IconButton onClick={() => handleEditModalOpen(selectedDisciple)}>
                            <Edit />
                          </IconButton>
                        </Box>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '0.5rem'
                          }}
                        >
                          {discipleData?.title !== '' ? (
                            discipleData?.title?.split(',').map((item, index, array) =>
                              index === 2 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {[2, 3, 4].map(i => array[i]).join(' • ')}
                                </Typography>
                              ) : index === array.length - 1 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {`${item} ${translate('on the Pathway')}`}
                                </Typography>
                              ) : ![3, 4].includes(index) ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {item}
                                </Typography>
                              ) : null
                            )
                          ) : (
                            <NoDataFoundMessage mt={5} message={translate('No assessment data available.')} />
                          )}
                        </div>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </AdminStructure>
      {/* modal for Add disciple to group */}
      <Modal
        open={isAddDiscipleOpen}
        onClose={handleMenuClose}
        title={translate('Add Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: 'Cancel', onClick: () => setIsAddDiscipleOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: 'Save',
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: () => handleDiscipleSave()
          }
        ]}
      >
        <AddDisciple setFormData={setDisData} formData={disData} />
      </Modal>

      {/* MOdal for drop disciple */}
      <Modal
        open={isModalOpen}
        onClose={handleMenuClose}
        title={translate('Drop Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: translate('Delete'),
            onClick: handleDelete,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !isChecked
          }
        ]}
      >
        <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to drop this disciple from the group?')}</Typography>
        <FormControlLabel
          sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '15px', fontWeight: 550}}}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                color: '#000000',
                '&.Mui-checked': {
                  color: '#000000'
                }
              }}
            />
          }
          label={translate('I am sure that I want to drop this disciple.')}
        />
      </Modal>

      {/* Modal for promote disciple */}
      <Modal
        open={isPromoteOpen}
        onClose={handleMenuClose}
        title={translate('Approve Disciple Leader')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: () => setIsPromoteOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Save'), onClick: handlePromoteSave, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c', disabled: false}
        ]}
      >
        <Typography sx={{mb: 1, fontSize: '16px', color: '#000000'}}>{translate('Select each year that the disciple is ready to lead.')}</Typography>

        {/* Wrapper for checkboxes with minimal spacing */}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={d1Checked}
                onChange={handlePromoteChange(setD1Checked)}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2 // Reduced padding for compact look
                }}
              />
            }
            label={translate('D1')}
          />
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={d2Checked}
                onChange={handlePromoteChange(setD2Checked)}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2
                }}
              />
            }
            label={translate('D2')}
          />
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={d3Checked}
                onChange={handlePromoteChange(setD3Checked)}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2
                }}
              />
            }
            label={translate('D3')}
          />
        </Box>
      </Modal>

      {/* Modal for edit disciple */}
      <Modal
        open={isEditModalOpen}
        onClose={handleEditModalClose}
        title={translate('Edit Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: handleEditModalClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Save'), onClick: () => handleSaveChanges(), buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c'}
        ]}
      >
        {<EditDisciple formData={formData} setFormData={setFormData} selectedDiscipleId={selectedDisciple?.id} />}
      </Modal>

      {/* Modal for activate disciple*/}
      <Modal
        open={isActivateOpen}
        onClose={closeActivateModal}
        title={translate('Activate Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: closeActivateModal, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Save'), onClick: onSaveActivateDisciple, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c'}
        ]}
      >
        <Typography sx={{color: '#000000'}}>{translate('Are you sure you want to activate this disciple?')}</Typography>
      </Modal>
    </div>
  );
}

export default Disciples;