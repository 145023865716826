import React, {useState, useEffect, useRef} from 'react';
import {Grid, Typography} from '@mui/material';
import DatePicker from '../../../components/common/DatePicker';
import useTranslations from '../../../hooks/useTranslation';
import {CONSTANTS} from '../../../constants';
import moment from 'moment';
import {validatePhone} from '../../../utils';
import AdminTextInput from './AdminTextInput';
import {getAddressApi} from '../../../api';

const EditDisciple = ({formData, setFormData, selectedDiscipleId}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOpen2, setDateOpen2] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const zipRef = useRef();

  const handleChange = e => {
    setErrorMsg(null);
    const {name, value} = e.target;
    if (name === 'birthday' && formData.salvation_year && moment(formData.salvation_year).isBefore(formData.birthday)) {
      setErrorMsg(translate('Salvation year must be after birthday'));
    } else {
      setErrorMsg('');
    }
    if (name === 'zipcode') {
      const trimmedValue = value.replace(/[^a-zA-Z0-9-]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: trimmedValue
      }));
    } else if (name === 'city' || name === 'state' || name === 'country') {
      const trimmedValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: trimmedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhoneInputChange = e => {
    setErrorMsg(null);
    const phoneNumber = e.target.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(phoneNumber)) {
      return;
    }
    setFormData(prevData => ({
      ...prevData,
      mobile_number: phoneNumber
    }));
  };

  const handleEmailChange = e => {
    const email = e.target.value;

    setErrorMsg(null);
    setFormData(prevData => ({
      ...prevData,
      email: email
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!String(formData.zipcode).trim() || !String(formData.country).trim() || !String(formData.city).trim() || !String(formData.state).trim() || !formData.birthday || !formData.salvation_year || !formData.mobile_number) {
      setErrorMsg(translate('All fields are required!'));
      return;
    } else {
      if (!validatePhone(formData.mobile_number)) {
        setErrorMsg(translate('Please enter valid phone number!'));
        return;
      }
      onSave(formData);
    }
  };

  const getZipCodeHandler = async () => {
    try {
      setLoading(true);
      const response = await getAddressByZipcode(formData?.zipcode);
      if (response.data.status === 200) {
        setFormData(prev => {
          return {
            ...prev,
            city: response?.data?.data?.city,
            state: response?.data?.data?.state,
            country: response?.data?.data?.county_area
          };
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAddressHandler = async id => {
    try {
      setLoading(true);
      const response = await getAddressApi(id);
      if (response?.data?.status === 200) {
        setFormData({
          zipcode: response?.data?.data?.zipcode,
          city: response?.data?.data?.city,
          state: response?.data?.data?.state,
          country: response?.data?.data?.country,
          birthday: response?.data?.data?.birthday,
          salvation_year: response?.data?.data?.salvation_year,
          mobile_number: response?.data?.data?.mobile_number || formData?.mobile_number,
          email: response?.data?.data?.email || formData?.email,
          user_id: selectedDiscipleId
        });
        if (response?.data?.data?.zipcode) {
          setIsBackEnable(true);
        } else {
          setIsBackEnable(false);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDiscipleId) {
      getAddressHandler(selectedDiscipleId);
    }
  }, [selectedDiscipleId]);

  useEffect(() => {
    if (String(formData?.zipcode).length === 5) {
      getZipCodeHandler();
    }
  }, [formData?.zipcode]);

  return (
    <form style={{display: 'flex', flexDirection: 'column', gap: '10px'}} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{color: '#000000'}}>
          {translate('WHERE ARE THEY LOCATED')}
        </Typography>
        <AdminTextInput name="zipcode" value={formData.zipcode} autoComplete="off" onChange={handleChange} placeholder={translate('Zip Code')} maxLength={CONSTANTS.ZIPCODE_MAX_LENGTH} inputRef={zipRef} labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput name="city" value={formData.city} autoComplete="off" onChange={handleChange} placeholder={translate('City')} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput name="state" value={formData.state} autoComplete="off" onChange={handleChange} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} placeholder={translate('State')} labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput name="country" value={formData.country} autoComplete="off" onChange={handleChange} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} placeholder={translate('Country')} labelVariant="body2" />
      </Grid>

      <Typography variant="subtitle1" sx={{color: '#000000', mb: -2}}>
        {translate('BIRTHDAY')}
      </Typography>
      <DatePicker open={dateOpen} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} name="birthday" value={formData?.birthday ? moment(formData?.birthday) : ''} onChange={date => handleChange({target: {name: 'birthday', value: date}})} maxDate={moment(new Date()).subtract(18, 'year')} newStyle={{border: '1px solid #000', height: '31px', marginTop: '10px', color: '#000 !important'}} sx={{borderRadius: '8px', border: '1px solid #ccc !important', '& .MuiInputBase-input': {color: 'black !important'}}} className="lightCustomDatePicker" />

      <Typography variant="subtitle1" sx={{color: '#000000', mb: -2}}>
        {translate('SALVATION YEAR')}
      </Typography>
      <DatePicker
        open={dateOpen2}
        setOpen={() => setDateOpen2(true)}
        onClose={() => setDateOpen2(false)}
        views={['year']}
        format={'YYYY'}
        name="salvation_year"
        newStyle={{border: '1px solid #000', height: '31px', marginTop: '10px', color: '#000 !important'}}
        sx={{borderRadius: '8px', border: '1px solid #ccc !important', '& .MuiInputBase-input': {color: 'black !important'}}}
        className="lightCustomDatePicker"
        value={formData?.salvation_year ? moment(formData?.salvation_year, 'YYYY') : ''}
        onChange={date =>
          handleChange({
            target: {name: 'salvation_year', value: date ? date.format('YYYY') : ''}
          })
        }
        minDate={formData.birthday ? moment(formData.birthday) : moment(new Date()).subtract(120, 'years')}
        maxDate={moment(new Date()).add(10, 'years')}
      />
      <Typography variant="subtitle1" sx={{color: '#000000', mb: -1}}>
        {translate('PHONE')}
      </Typography>
      <AdminTextInput name="mobile_number" value={formData.mobile_number} placeholder={translate('Phone Number')} maxLength={CONSTANTS.MOBILE_NUMBER_MAX_LENGTH} onChange={handlePhoneInputChange} autoComplete="off" labelVariant="body2" />

      <Typography variant="subtitle1" sx={{color: '#000000', mb: -1}}>
        {translate('EMAIL')}
      </Typography>
      <AdminTextInput name="email" value={formData.email} placeholder={translate('Email')} onChange={handleEmailChange} autoComplete="off" labelVariant="body2" />
    </form>
  );
};

export default EditDisciple;