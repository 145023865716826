import React, {useEffect, useState} from 'react';
import {Typography, FormControl, Radio, RadioGroup, FormControlLabel, Grid, Avatar, Box, CircularProgress} from '@mui/material';
import Modal from '../../../components/common/Modal';
import DatePicker from '../../../components/common/DatePicker';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {BASE_URL, CONSTANTS} from '../../../constants';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from '../components/AdminTextInput';
import StyledSelectInput from '../components/StyledSelectInput';
import {getAdminLeadersGroupApi, getAllReadingPlansApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import TimePickerComponent from '../../../components/common/TimePicker';
import {disableDatesByWeekDay, isEmptyObjectValues, isValidFile} from '../../../utils';
import toast from 'react-hot-toast';

const AddGroupModal = ({open, setOpen, getData}) => {
  const {translate} = useTranslations();
  const {selectedOrganization, token} = useAuthStore(state => state);
  const [existingLeader, setExistingLeader] = useState([]);
  const [readingPlan, setReadingPlan] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [groupData, setGroupData] = useState({
    organization_id: selectedOrganization.id,
    name: '',
    leader: '',
    avatar: '',
    meeting_day: 'monday',
    meeting_time: moment(),
    starting_time: moment(),
    meeting_place: '',
    meeting_weeks: 48,
    timezone: 'UTC',
    gender: '',
    level: '',
    reading_plan_id: ''
  });
  const dayOfWeek = [
    {label: translate('Monday'), value: 'monday'},
    {label: translate('Tuesday'), value: 'tuesday'},
    {label: translate('Wednesday'), value: 'wednesday'},
    {label: translate('Thursday'), value: 'thursday'},
    {label: translate('Friday'), value: 'friday'},
    {label: translate('Saturday'), value: 'saturday'},
    {label: translate('Sunday'), value: 'sunday'}
  ];
  const [dateOpen, setDateOpen] = useState(false);

  const handleSave = async() => {
    let groupObject = groupData;
    delete groupObject['avatar'];
    delete groupObject['leader'];
    groupObject['starting_time'] = moment(groupObject['starting_time']).format('YYYY-MM-DD')
        const isEmptyKey = isEmptyObjectValues(groupObject);
    if (isEmptyKey) {
      toast.error(translate(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED), {id: 'toast_message'});
      return;
    }
    try {
      const formData = new FormData();
      Object.keys(groupData).map((key) => {
        formData.append(key, groupData[key]);
      });
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formData
      };
      setButtonLoader(true);
      const res = await fetch(`${BASE_URL}/group?accept=all`, requestOptions);
      if(res.ok) {
        toast.success(CONSTANTS.MESSAGES.GROUP_CREATED)
        setOpen(false);
        setGroupData({
          organization_id: selectedOrganization.id,
          name: '',
          leader: '',
          avatar: '',
          meeting_day: 'monday',
          meeting_time: moment(),
          starting_time: moment(),
          meeting_place: '',
          meeting_weeks: 48,
          timezone: 'UTC',
          gender: '',
          level: '',
          reading_plan_id: ''
        })
      }
      setButtonLoader(false);
      if(getData) {
        getData()
      }
    } catch (error) {
      setButtonLoader(false);
      toast.error(error?.message || CONSTANTS.MESSAGES.SOMETHING_WRONG);
    }
  };

  const imageChangeHandler = e => {
    const file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setGroupData(prev => ({...prev, avatar: e.target.files[0]}));
    } else {
      toast.error(translate('Only image file allowed!'));
      return;
    }
  };

  const handleChangeInput = (name, value) => {
    setGroupData(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const getAllLeadersHandler = async () => {
    try {
      const res = await getAdminLeadersGroupApi(selectedOrganization.id);
      if (res.data.status === 200) {
        const leaders = res?.data?.data?.map(leader => {
          return {
            label: leader.name,
            value: leader.id
          };
        });
        setExistingLeader(leaders);
      }
    } catch (error) {}
  };

  const getAllReadingPlanHandler = async () => {
    try {
      const res = await getAllReadingPlansApi();
      if (res?.data?.status === 200) {
        const readingPlans = res?.data?.data?.map(readingPlan => {
          return {
            label: readingPlan.name,
            value: readingPlan.id
          };
        });
        setReadingPlan(readingPlans);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllLeadersHandler();
    getAllReadingPlanHandler();
  }, []);

  return (
    <Modal
      open={open}
      setOpen={() => setOpen(false)}
      title={translate('Add Group')}
      modalbgColor="#ffffff"
      modalTextColor="#000000"
      modalTitleColor="#d32f2f"
      buttons={[
        {name: translate('Cancel'), onClick: () => setOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
        {
          name: buttonLoader ? translate('Saving') : translate('Save'),
          onClick: handleSave,
          buttonBgColor: '#d32f2f',
          buttonTextColor: '#ffffff',
          buttonHoverColor: '#b71c1c'
        }
      ]}
    >
      {/* do not remove this code will uncomment later if needed */}
      {/* <FormControl fullWidth sx={{mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <label style={{cursor: 'pointer'}} htmlFor="contained-button-file">
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative'
            }}
          >
            <Avatar
              src={URL.createObjectURL(groupData.avatar)}
              sx={{
                width: 60,
                height: 60
              }}
            />
            <EditIcon
              sx={{
                color: 'black',
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'translate(25%, 25%)', // Adjust this to position the icon better if needed
                zIndex: 1,
                backgroundColor: 'whitesmoke',
                borderRadius: '50%',
                padding: '2px'
              }}
            />
          </Box>
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
        </label>
      </FormControl> */}

      <FormControl fullWidth>
        <AdminTextInput autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={groupData.name} onChange={e => handleChangeInput('name', e.target.value)} fullWidth bottomLabel={translate('Enter group name')} />
      </FormControl>

      <FormControl fullWidth>
        <StyledSelectInput value={groupData.leader} onChange={e => handleChangeInput('leader', e.target.value)} fullWidth variant="outlined" optionArray={existingLeader} bottomLabel={translate('Select Existing Leader')} labelTopGap={-2.5} />
      </FormControl>

      <FormControl fullWidth>
        <RadioGroup
          row
          sx={{
            color: '#d32f2f !important',
            '& .MuiFormControlLabel-label': {
              color: '#d32f2f !important',
              fontSize: '16px'
            }
          }}
          value={groupData.level}
          onChange={e => handleChangeInput('level', e.target.value)}
        >
          <FormControlLabel
            value="D1"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D1')}
          />
          <FormControlLabel
            value="D2"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D2')}
          />
          <FormControlLabel
            value="D3"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D3')}
          />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth>
        <RadioGroup
          row
          sx={{
            color: '#d32f2f !important',
            '& .MuiFormControlLabel-label': {
              color: '#d32f2f !important',
              fontSize: '16px'
            }
          }}
          value={groupData.gender}
          onChange={e => handleChangeInput('gender', e.target.value)}
        >
          <FormControlLabel
            value="Co-Ed"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('Co-Ed')}
          />
          <FormControlLabel
            value="All Guys"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('All Guys')}
          />
          <FormControlLabel
            value="All Girls"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('All Girls')}
          />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth sx={{marginBottom: '8px'}}>
        <StyledSelectInput value={groupData.reading_plan_id} onChange={e => handleChangeInput('reading_plan_id', e.target.value)} fullWidth variant="outlined" optionArray={readingPlan} bottomLabel={translate('Select Reading Plan')} labelTopGap={-2.5}></StyledSelectInput>
      </FormControl>

      <FormControl fullWidth>
        <AdminTextInput autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={groupData.meeting_place} onChange={e => handleChangeInput('meeting_place', e.target.value)} fullWidth bottomLabel={translate('Where will you meet?')} mb={1} />
      </FormControl>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TimePickerComponent color1="black" color2="white" bottomLabel={translate('Time of Day')} value={moment(groupData.starting_time)} className="lightTimePicker" onChange={e => handleChangeInput('starting_time', e)} />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <StyledSelectInput value={groupData.meeting_day} onChange={e => handleChangeInput('meeting_day', e.target.value)} displayEmpty fullWidth optionArray={dayOfWeek} variant="outlined" bottomLabel={translate('Day of Week')} labelTopGap={-2.5} />
          </FormControl>
        </Grid>
      </Grid>

      <FormControl fullWidth sx={{marginBottom: '4px'}}>
        <StyledSelectInput value={groupData.timezone} onChange={e => handleChangeInput('timezone', e.target.value)} fullWidth variant="outlined" optionArray={CONSTANTS.TIMEZONE_OPTIONS} bottomLabel={translate('Meeting Timezone')} labelTopGap={-2.5}></StyledSelectInput>
      </FormControl>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <DatePicker 
              className="lightCustomDatePicker" 
              newStyle={{border: '1px solid #000', height: '31px', marginTop: '10px', color: '#000 !important'}} 
              sx={{borderRadius: '8px', border: '1px solid #ccc !important', '& .MuiInputBase-input': {color: 'black !important'}}} 
              open={dateOpen} 
              value={moment(groupData.meeting_time)} 
              onChange={e => handleChangeInput('meeting_time', e)} 
              setOpen={() => setDateOpen(true)} 
              onClose={() => setDateOpen(false)} 
              minDate={moment().subtract(1, 'year')} 
              showDaysOutsideCurrentMonth 
              shouldDisableDate={(date) => disableDatesByWeekDay(groupData.meeting_day, date)}
            />
            <Typography variant="body2" sx={{color: '#888888'}}>
              {translate('Date You Will Start')}
            </Typography>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <StyledSelectInput value={groupData.meeting_weeks} onChange={e => handleChangeInput('meeting_weeks', e.target.value)} optionArray={CONSTANTS.ALL_WEEKS} displayEmpty fullWidth variant="outlined" bottomLabel={translate('Number of Weeks')} labelTopGap={-2.5} />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddGroupModal;
