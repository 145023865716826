import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#fd4958'
    },
    error: {
      main: '#fff'
    },
    secondary: {
      main: '#02140A'
    },
    text: {
      primary: '#ffffff',
      secondary: 'red', // You can define your own custom text colors
    },
    icon: {
      primary: '#ffffff',
      secondary: '#ffffff', // You can define your own custom text colors
    },
    checkbox: {
      primary: '#fd4958',
    },
    loader: {
      primary: '#ffffff',
    },
    background: {
      primary: '#222',
      hover: '#d32f2f',
      secondary: 'linear-gradient(135deg, #d0373b, #6d0407)',
    },
    modal: {
      bgcolor: '#9e0001',
      textcolor: '#ffffff',
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      color: '#ffffff'
    },
    h2: {
      color: '#ffffff'
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 900,
      color: '#ffffff'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body1: {
      fontSize: '.9rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body2: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body3: {
      fontSize: '0.8rem',
      fontWeight: 200,
      color: '#ffffff'
    },
    body4: {
      fontSize: '2rem',
      fontWeight: 800,
      color: '#ffffff'
    },
    subtitle2: {
      fontSize: '0.6rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    button: {
      fontSize: '1rem',
      // fontWeight: 400,
      color: '#ffffff'
    },
    caption:{
       color: '#ffffff'
    },
    p: {
       color: '#ffffff'
    }
  },
  components: {
    MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundImage: 'linear-gradient(330deg, #ca0000 64%, #9e0001 58%)',
        backgroundColor: '#ca0000',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflowX: 'hidden',
      },
    },
  },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: '600',
          width: '100%',
          '@media (max-width: 600px)': {
            // Adjust the max-width according to your requirements
            height: '3.2rem'
          },
          '@media (min-width: 601px)': {
            // Set default height for larger screens
            height: '2.8rem'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
          backdropFilter: 'blur(5px)',
          border: 'none',
          boxShadow: 'none',
          width: '250px',
          '& .MuiListItemText-primary': {
            fontSize: '1.2rem'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'rgba(255,255,255,0.5) !important',
          '& .MuiAlert-message': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#fff'
          },
          '& .MuiAlert-action': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#fff'
          },

          '& .MuiAlert-icon': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#fff'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select': {
            outline: 'none',
            border: 'none',
            textAlign: 'left'
          },
          '&. MuiTypography-root': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#fff'
          }
        },
        root: {
          color: 'white',
          border: 'none',
          borderRadius: '16px',
          background: 'rgba(255,255,255,0.5) !important'
        },
        icon: {
          color: 'white'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.1rem',
          color: '#ffffff'
        },
        '& .Mui focused': {
          color: '#fff'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          color: '#ffffff',
          textAlign: 'left'
        }
      }
    },
    MuiRadioGroup: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '12px',
          '& .MuiTypography-root': {
            color: '#fd4958',
            fontSize: '1.4rem'
          },
          '&:first-of-type': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          width: '100%',
          '& .MuiInputBase-input:not(.text-black)': {
            padding: '10px !important',
            borderRadius: '12px !important',
            backgroundColor: 'rgba(255,255,255,0.5) !important',
            color: 'white',
            fontSize: '1.2rem'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none !important'
            }
          },
          '& .MuiInputBase-input:focus': {
            backgroundColor: 'rgba(255,255,255,0.5) !important'
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'white !important',
            fontSize: '1rem !important'
          },
          '& .MuiInputBase-root.Mui-error': {
            border: 'none !important'
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: '#fff !important'
          },
          '& .MuiInputLabel-root': {
            fontSize: '1.2rem !important'
          }
        }
      }
    }
  }
});
export default theme;

// new theme?
export const greenTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#c3fd49'
    },
    error: {
      main: '#fff'
    },
    secondary: {
      main: '#d6e2db'
    },
    text: {
      primary: '#baea10',
      secondary: '#baea10', // You can define your own custom text colors
    },
    icon: {
      primary: '#baea10',
      secondary: '#baea10', // You can define your own custom text colors
    },
    checkbox: {
      primary: '#c3fd49',
    },
    loader: {
      primary: '#baea10',
    },
    background: {
      primary: '#4caf50',
      hover: '#388e3c',
      secondary: '#388e3c',
    },

    modal: {
      bgcolor: '#4caf50',
      textcolor: '#baea10',
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      color: '#baea10'
    },
    h2: {
      color: '#baea10'
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 900,
      color: '#baea10'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#baea10'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 400,
      color: '#baea10'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#baea10'
    },
    body1: {
      fontSize: '.9rem',
      fontWeight: 400,
      color: '#baea10'
    },
    body2: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#baea10'
    },
    body3: {
      fontSize: '0.8rem',
      fontWeight: 200,
      color: '#baea10'
    },
    body4: {
      fontSize: '2rem',
      fontWeight: 800,
      color: '#baea10'
    },
    subtitle2: {
      fontSize: '0.6rem',
      fontWeight: 400,
      color: '#baea10'
    },
    button: {
      fontSize: '1rem',
      // fontWeight: 400,
      color: '#baea10'
    },
    p: {
      color: '#baea10'
   }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: 'linear-gradient(330deg, #4caf50 64%, #388e3c 58%)',
          backgroundColor: '#4caf50',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflowX: 'hidden',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: '600',
          width: '100%',
          '@media (max-width: 600px)': {
            // Adjust the max-width according to your requirements
            height: '3.2rem'
          },
          '@media (min-width: 601px)': {
            // Set default height for larger screens
            height: '2.8rem'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundColor: 'rgba(192,192,192,0.3) !important',
          backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
          backdropFilter: 'blur(5px)',
          border: 'none',
          boxShadow: 'none',
          width: '250px',
          '& .MuiListItemText-primary': {
            fontSize: '1.2rem'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'rgba(255,255,255,0.5) !important',
          '& .MuiAlert-message': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#baea10'
          },
          '& .MuiAlert-action': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#baea10'
          },

          '& .MuiAlert-icon': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#baea10'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select': {
            outline: 'none',
            border: 'none',
            textAlign: 'left'
          },
          '&. MuiTypography-root': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#baea10'
          }
        },
        root: {
          color: '#baea10',
          border: 'none',
          borderRadius: '16px',
          background: 'rgba(0,255,0,0.3) !important'
        },
        icon: {
          color: '#baea10'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.1rem',
          color: '#baea10'
        },
        '& .Mui focused': {
          color: '#baea10'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#baea10'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          color: '#baea10',
          textAlign: 'left'
        }
      }
    },
    MuiRadioGroup: {
      styleOverrides: {
        root: {
          color: '#baea10'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#baea10',
          borderRadius: '12px',
          '& .MuiTypography-root': {
            color: '#c3fd49',
            fontSize: '1.4rem'
          },
          '&:first-of-type': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          width: '100%',
          '& .MuiInputBase-input:not(.text-black)': {
            padding: '10px !important',
            borderRadius: '12px !important',
            backgroundColor: 'rgba(0,255,0,0.3) !important',
            color: 'white',
            fontSize: '1.2rem !important'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none !important'
            }
          },
          '& .MuiInputBase-input:focus': {
            backgroundColor: 'rgba(0,255,0,0.3)!important'
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'white !important',
            fontSize: '1rem !important'
          },
          '& .MuiInputBase-root.Mui-error': {
            border: 'none !important'
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: '#fff !important'
          },
          '& .MuiInputLabel-root': {
            fontSize: '1.2rem !important'
          }
        }
      }
    }
  }
});


// /85555555555555555555555555555

export const orangeTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#f57c00'
    },
    error: {
      main: '#fff'
    },
    secondary: {
      main: '#02140A'
    },
    text: {
      primary: '#ffc78e',
      secondary: '#f75606', // You can define your own custom text colors
    },
    icon: {
      primary: '#ffc78e',
      secondary: '#f75606', // You can define your own custom text colors
    },
    checkbox: {
      primary: '#f57c00',
    },
    loader: {
      primary: '#ffc78e',
    },
    background: {
      primary: '#f57c00',
      hover: '#ff9800',
      secondary: '#ff9800',
    },
    modal: {
      bgcolor: '#f57c00',
      textcolor: '#ffc78e',
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      color: '#ffc78e'
    },
    h2: {
      color: '#ffc78e'
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 900,
      color: '#ffc78e'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    body1: {
      fontSize: '.9rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    body2: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    body3: {
      fontSize: '0.8rem',
      fontWeight: 200,
      color: '#ffc78e'
    },
    body4: {
      fontSize: '2rem',
      fontWeight: 800,
      color: '#ffc78e'
    },
    subtitle2: {
      fontSize: '0.6rem',
      fontWeight: 400,
      color: '#ffc78e'
    },
    button: {
      fontSize: '1rem',
      // fontWeight: 400,
      color: '#ffc78e'
    },
    p: {
      color: '#ffc78e'
   }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: 'linear-gradient(330deg, #f57c00 64%, #ff9800 58%)',
          backgroundColor: '#f57c00',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflowX: 'hidden',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: '600',
          width: '100%',
          '@media (max-width: 600px)': {
            // Adjust the max-width according to your requirements
            height: '3.2rem'
          },
          '@media (min-width: 601px)': {
            // Set default height for larger screens
            height: '2.8rem'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(235, 149, 50, 1) !important',
          backdropFilter: 'blur(5px)',
          border: 'none',
          boxShadow: 'none',
          width: '250px',
          '& .MuiListItemText-primary': {
            fontSize: '1.2rem'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'rgba(242,120,75,1) !important',
          '& .MuiAlert-message': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#ffc78e'
          },
          '& .MuiAlert-action': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#ffc78e'
          },

          '& .MuiAlert-icon': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#ffc78e'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select': {
            outline: 'none',
            border: 'none',
            textAlign: 'left'
          },
          '&. MuiTypography-root': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#ffc78e'
          }
        },
        root: {
          color: 'white',
          border: 'none',
          borderRadius: '16px',
          background: 'rgba(242,120,75,1) !important'
        },
        icon: {
          color: 'white'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.1rem',
          color: '#ffc78e'
        },
        '& .Mui focused': {
          color: '#ffc78e'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#ffc78e'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          color: '#ffc78e',
          textAlign: 'left'
        }
      }
    },
    MuiRadioGroup: {
      styleOverrides: {
        root: {
          color: '#ffc78e'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '12px',
          '& .MuiTypography-root': {
            color: '#ffc78e',
            fontSize: '1.4rem'
          },
          '&:first-of-type': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          width: '100%',
          '& .MuiInputBase-input:not(.text-black)': {
            padding: '10px !important',
            borderRadius: '12px !important',
            backgroundColor: 'rgba(242,120,75,1) !important',
            color: '#ffc78e',
            fontSize: '1.2rem !important'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none !important'
            }
          },
          '& .MuiInputBase-input:focus': {
            backgroundColor: 'rgba(242,120,75,1) !important'
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'white !important',
            fontSize: '1rem !important'
          },
          '& .MuiInputBase-root.Mui-error': {
            border: 'none !important'
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: '#fff !important'
          },
          '& .MuiInputLabel-root': {
            fontSize: '1.2rem !important'
          }
        }
      }
    }
  }
});


