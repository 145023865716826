import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import {Box} from '@mui/system';
import {getAdminOrgDisciplesApi, getAllDisciples} from '../../../api';
import useAuthStore from '../../../store/authStore';

const AdminStructure = ({children, handleAddClick, isSearch, searchHandler, heading, isAddingUser, isAddingGroup, isAddingDisciple, isAddingOrganisation}) => {
  const {user, selectedOrganization} = useAuthStore(state => state);
  const [disciples, setDisciples] = useState(0);

  const getDiscipleDataHandler = async () => {
    try {
      let res;
      if(user.user_role === "SUPERADMIN") {
        res = await getAllDisciples();
        if (res?.data?.status === 200) {
          setDisciples(res?.data?.data?.disciples || 0);
        }
      }else {
        res = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple');
        if (res?.data?.status === 200) {
          setDisciples(res?.data?.data?.length || 0);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDiscipleDataHandler();
  }, [selectedOrganization]);


  return (
    <Box display={'flex'}>
      <Sidebar totalDisciples={disciples || 0} />
      <Box height={'auto'} minHeight="100vh" flexGrow={1} sx={{width: 'calc(100vw - 300px)'}}>
        <DashboardHeader user={user} heading={heading} isAddingUser={isAddingUser} handleAddClick={handleAddClick} isSearch={isSearch} searchHandler={searchHandler} isAddingGroup={isAddingGroup} isAddingDisciple={isAddingDisciple} isAddingOrganisation={isAddingOrganisation} />
        {children}
      </Box>
    </Box>
  );
};

export default AdminStructure;
